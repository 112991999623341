import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

//icons
import BusinessIcon from "@material-ui/icons/Business";
import RoomIcon from "@material-ui/icons/Room";
import PhoneIcon from "@material-ui/icons/Phone";
import PrintIcon from "@material-ui/icons/Print";
import MailIcon from "@material-ui/icons/Mail";
import PublicIcon from "@material-ui/icons/Public";

import img1 from "../assets/picture/logo_footer.png";

export default class Footer extends Component {
  render() {
    return (
      <footer className="bg-dark mt-0">
        <Container>
          <Row>
            <Col sm={6} className="px-auto">
              <Row className="d-flex justify-content-center">
                <img
                  className="footer-logo mb-2"
                  src={img1}
                  alt="Company Logo"
                />
              </Row>
            </Col>
            <Col sm={6}>
              <ul>
                <li className="text-sm-left">
                  <span>
                    <BusinessIcon className="text-white-50"></BusinessIcon> ENI
                    Dist. Inc. HEADQUARTER
                  </span>
                </li>
                <li className="text-sm-left">
                  <span>
                    <RoomIcon className="text-white-50"></RoomIcon> 7020
                    Columbia Gateway Dr. Columbia, MD 21046
                  </span>
                </li>
                <li className="text-sm-left">
                  <span>
                    <a href="tel:(443) 546-3673">
                      <PhoneIcon></PhoneIcon> (301) 477-4010
                    </a>
                  </span>
                </li>
                <li className="text-sm-left">
                  <span>
                    <PrintIcon className="text-white-50"></PrintIcon> (877)
                    384-0155
                  </span>
                </li>
                <li className="text-sm-left">
                  <span>
                    <a href="mailto:info@enidist.com">
                      <MailIcon></MailIcon> info@enidist.com
                    </a>
                  </span>
                </li>
                <li className="text-sm-left">
                  <span>
                    <a href="https://www.enidist.com">
                      <PublicIcon></PublicIcon> www.enidist.com
                    </a>
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}
