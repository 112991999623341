import React from 'react';
import axios from 'axios';

import { Col, Button, Form } from 'react-bootstrap';

export default class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { feedback: '' };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.inputValidation = this.inputValidation.bind(this);
    }

    handleChange(event) {
        this.setState({feedback: event.target.value});
    }
    
    handleSubmit(e) {
        e.preventDefault();
        
        if(this.inputValidation()[0]){
            window.grecaptcha.ready(function() {
                window.grecaptcha.execute('6LfNCQAVAAAAADjSoP4mh8wEq-HWiswQ0jaWjFEl', {action: 'submit'}).then(function(token) {
                    const templateId = 'template_2gE2Zu1l_clone';
                    let from_name = document.querySelectorAll('input')[0].value;
                    let from_email = document.querySelectorAll('input')[1].value;
                    let from_website = document.querySelectorAll('input')[2].value;
                    let query_message = document.querySelector("textarea").value;
                    
                    document.querySelectorAll('input')[0].value = null;
                    document.querySelectorAll('input')[1].value = null;
                    document.querySelectorAll('input')[2].value = null;
                    document.querySelector("textarea").value = null;

                    window.emailjs.send(
                        'gmail', templateId,
                        {message_html: query_message, from_name: from_name, reply_to: from_email, website: from_website}
                    ).then(res => {
                        console.log('Email successfully sent!');
                    })
                    // Handle errors here however you like, or use a React error boundary
                    .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err));        
                    
                    axios.post('https://us-central1-eni-dist.cloudfunctions.net/api/storeContact', {
                        sender : from_name,
                        email : from_email,
                        website : from_website,
                        query : query_message
                    });
                });
            });
        }else{
            console.log("checking input");
        }
    }

    inputValidation(){
        let from_name = document.querySelectorAll('input')[0].value;
        let from_email = document.querySelectorAll('input')[1].value;
        let from_website = document.querySelectorAll('input')[2].value;
        let query_message = document.querySelector("textarea").value;

        if(!from_name || !from_email || !query_message){
            return [false];
        }else{
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(from_email))
            {
                return [true];
            }else{
                return [false];
            }
        }
    }

    render() {
        return (
            <div className="mx-3 w-100">
                <Col xs={12} className="my-5">
                    <h3 className="d-flex">Contact Form</h3>
                </Col>
                <Form>
                    <Form.Row>
                        <Col sm={4} className="pb-3">
                            <input type="text" className="form-control" placeholder="Name*"></input>
                        </Col>
                        <Col sm={4} className="pb-3">
                            <input type="email" className="form-control" placeholder="Email*"></input>
                        </Col>
                        <Col sm={4} className="pb-3">
                            <input type="text" className="form-control" placeholder="website"></input>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col xs={12} className="mt-2">
                            <textarea 
                                className="form-control" 
                                rows="8"
                                onChange={this.handleChange}
                                placeholder="Message*"
                                required
                                value={this.state.feedback}
                            ></textarea>
                        </Col>
                    </Form.Row>
                    <Button onClick={this.handleSubmit} className="btn-white-50 mt-3 ml-3 w-25">SUBMIT</Button>
                </Form>
            </div>
        );
    }

}