import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import TransLogo from '../assets/picture/logo_trans.png';
import LogoSvg from '../assets/picture/logo.svg';

export default class customNavbar extends Component {
    render() {
        return (
            <Navbar collapseOnSelect expand="md" bg="light" variant="light" id="navbar">
                <div className="container pt-0">
                    <Navbar.Brand>
                        <Button component={Link} to="/">
                            <img src={LogoSvg} alt="Company Logo"/>
                        </Button>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" className="border-secondary mr-3" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                    
                        <Nav className="ml-auto">
                            <Button className="font-anton" component={Link} to="/">Home</Button>
                            <Button className="font-anton" component={Link} to="/aboutus">About Us</Button>
                            <Button className="font-anton" component={Link} to="/products">Products</Button>
                            {/* <Button className="font-anton" component={Link} to="/products">Products</Button> */}
                            <Button className="font-anton" component={Link} to="/contact">Contact</Button>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        )
    }
}


