import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

//Components
import MailForm from "../components/Form";

//icons
import RoomIcon from "@material-ui/icons/Room";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";

export default class Contact extends Component {
  render() {
    return (
      <Container className="text-center fullVh pb-5">
        <Row>
          <iframe
            width="100%"
            height="500"
            frameBorder="0"
            title="contact-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3092.973737083645!2d-76.80637498723524!3d39.175317771547824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7e092c5007c1d%3A0x8c0f0cd936adc5d!2s7020%20Columbia%20Gateway%20Dr%2C%20Columbia%2C%20MD%2021046!5e0!3m2!1sen!2sus!4v1701572745731!5m2!1sen!2sus"
            allowFullScreen
          ></iframe>
        </Row>
        <Row className="my-5 py-5">
          <Col sm={4}>
            <Row className="d-flex justify-content-center">
              <RoomIcon fontSize="large" color="error" />
            </Row>
            <p className="home-text">
              7020 Columbia Gateway Dr. Columbia, MD 21046
            </p>
          </Col>
          <Col sm={4}>
            <Row className="d-flex justify-content-center">
              <PhoneIcon fontSize="large" color="error" />
            </Row>
            <p className="home-text">(301) 477-4010</p>
          </Col>
          <Col sm={4}>
            <Row className="d-flex justify-content-center">
              <MailIcon fontSize="large" color="error" />
            </Row>
            <p className="home-text">info@enidist.com</p>
          </Col>
        </Row>
        <Row>
          <iframe
            width="100%"
            height="500"
            frameBorder="0"
            title="contact-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.9705492392504!2d-84.25669852322534!3d33.83887012896511!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5a80a2a1034c3%3A0xd0225b907d591fa3!2s3289%20Montreal%20Industrial%20Way%2C%20Tucker%2C%20GA%2030084!5e0!3m2!1sen!2sus!4v1702173607845!5m2!1sen!2sus"
            allowFullScreen
          ></iframe>
        </Row>
        <Row className="my-5 py-5">
          <Col sm={4}>
            <Row className="d-flex justify-content-center">
              <RoomIcon fontSize="large" color="error" />
            </Row>
            <p className="home-text">
              3289 Montreal Industrial Way Tucker, GA 30084
            </p>
          </Col>
          <Col sm={4}>
            <Row className="d-flex justify-content-center">
              <PhoneIcon fontSize="large" color="error" />
            </Row>
            <p className="home-text">(301) 477-4010</p>
          </Col>
          <Col sm={4}>
            <Row className="d-flex justify-content-center">
              <MailIcon fontSize="large" color="error" />
            </Row>
            <p className="home-text">info@enidist.com</p>
          </Col>
        </Row>
        <Row>
          <MailForm />
        </Row>
      </Container>
    );
  }
}
