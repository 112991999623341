import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import DistMap from '../components/DistMap';

//animeJS
import "../assets/css/anime.css";

import img1 from '../assets/picture/gobchang.jpg';
import img3 from '../assets/picture/who_crop.jpg';
import img4 from '../assets/picture/meat.jpg';

export default class Home extends Component {

	componentDidMount(){
		var textWrapper = document.querySelectorAll('.ml6 .letters');
		textWrapper.forEach(element => {
			element.innerHTML = element.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
		})  

		window.anime.timeline({loop: false})
			.add({
				targets: '.ml6 .letter',
				rotateY: [-90, 0],
				duration: 2000,
				delay: (el, i) => 50 * i
			})

		this.resizeOverlay = () => {
			var height = document.querySelector(".home-banner").height;
			document.querySelector(".heading-overlay").style.height = height + "px";
		}
		window.addEventListener('resize', this.resizeOverlay);

		window.onload = () => {
			document.querySelector(".heading-overlay").style.height = document.querySelector(".home-banner").height + "px";			
		}
	}

	componentWillUnmount(){
		window.removeEventListener('resize', this.resizeOverlay)
	}
    
    render() {
		
        return (
            <div>
                <div>
					<div className="heading-overlay"></div>
					<div className="heading-wrapper ml-5 mt-5 h-100">
						<h1 class="ml6">
							<span class="text-wrapper">
								<span class="letters">ENI Dist.</span><br />
								<span class="letters">Provides</span><br />
								<span class="letters">Everything You need</span><br />
								<span class="letters">for Asian Food Supply</span>
							</span>
						</h1>
					</div>
					<img className="home-banner" src={img1}></img>
				</div>
        		<Container className="d-flex justify-content-center pb-5">
        		    <div className="p-5 col-12">
        		        <Row className="d-flex flex-row-reverse my-5 py-5">
							<Col sm={12} md={6} className="justify-content-center text-center">
								<div className="pb-2 section-title">
									Who We Are
								</div>
								<div className="text-left pb-3 home-text">
								ENI DIST, Inc. imports and distributes authentic Asian food from South Korea and Southeast Asia. Our goal is to provide customers with quality Asian food through efficient and trustworthy service and to ensure a satisfactory customer experience. ENI DIST, Inc. currently services wholesale distributors, chain retailers, foodservice distributors, and independent supermarkets. Our warehouse manages dry, refrigerated, and frozen products, as well as container drop shipments. ENI's local and container sales teams are working hard to ensure that our products are delivered to the right place at the right time to meet the needs of our consumers.
								</div>
							</Col>
							<Col sm={12} md={6} className="justify-content-center text-center d-flex">
								<img className="img-fluid m-auto intro-img" src={img3} />
							</Col>
						</Row>
						<Row className="d-flex my-5 py-5">
							<Col sm={12} md={6} className="justify-content-center text-center">
								<div className="home-content-description">
									<div className="pb-2 section-title">
										What We Supply
									</div>
									<div className="text-left pb-3 home-text">
										Thanks to ENI, you can now easily experience the best Korean food brands right here in the United States. Find products at ENI that can change your business.
									</div>
								</div>
							</Col>
							<Col sm={12} md={6} className="justify-content-center text-center d-flex">
								<img className="img-fluid m-auto intro-img" src={img4} />
							</Col>
						</Row>
						<Row className="d-flex flex-row-reverse my-5 py-5">
							<Col sm={12} md={6} className="justify-content-center text-center">
								<div className="pb-2 section-title">
									Where We Cover
								</div>
								<div className="text-left pb-3 home-text">
									We distribute products to a variety of locations around the United States. We currently distribute to FL, GA, IL, MD, NJ, NY, TX, and VA through our local sales team (green states); our container sales team delivers directly to your business through the ports in Baltimore, Chicago, Houston, New York, Oakland, Savannah, and Seattle (blue states).; our supply lines are expanding yearly; contact our sales team and set up a delivery today!
								</div>
							</Col>
							<Col sm={12} md={6} className="justify-content-center text-center d-flex">
								<DistMap />
							</Col>
						</Row>
        		    </div>
        		</Container>
        	</div>
        );
    }
}