import React, { Component } from 'react';

import { Col, Row } from 'react-bootstrap';

import img1 from '../assets/picture/mod_brands.png';
import img2 from '../assets/picture/mgn.png';
import img3 from '../assets/picture/mgn-products.png';
import img4 from '../assets/picture/brands_top.jpg';
import img5 from '../assets/picture/uchon_resize.png';

export default class Brands extends Component {
    render() {
        return (
            <div className="pb-5">
                <div>
                    <img className="brands-banner" src={img4} alt="Brands Banner"/>
                </div>
                <div className="container text-center fullVh">
                    
                    <div className="my-5">
                        <div className="text-left">
                            <Row>
                                <Col xs={12} sm>
                                    <h1>
                                        Quality Products
                                    </h1>
                                </Col>
                            </Row>
                        </div>
                        <div className="my-5">
                            <div className="w-50 mx-auto home-text text-left">
                                ENI Dist. brings authentic Korean flavor to your table.<br/>
                                To see our Products Catalog, please click <a href="https://enidist.com/catalog.pdf" download='catalog' target='_blacnk'>here</a>
                            </div>
                        </div>
                        <div className="text-left">
                            <Row>
                                <Col xs={12} sm>
                                    <h1>
                                        Private Brands
                                    </h1>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <img className="brands-img" src={img2} />
                            <div className="w-50 mx-auto home-text text-left">
                                The first private brand of ENI Dist. In Korean "Myung Ga" means a reputable family. We named it to represent the finest quality and ingredient, just like a family's reputation.
                            </div>
                            <img src={img3} className="w-75" />
                        </div>
                        <div className="mt-5">
                            <img className="brands-img" src={img5} />
                            <div className="w-50 mx-auto home-text text-left">
                                Uhchon coop is a brand built to deliver fresh seafood from Korean fishing villages directly to consumers' dishes. You will be able to taste the freshness of the sea through the Uhchon coop Brand.
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="text-left">
                            <Row>
                                <Col xs={12} sm>
                                    <h1>
                                        National Brands
                                    </h1>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <div className="home-text w-75 mx-auto text-left my-3">
                                We offer quality national brands from all across Asia. We continuously make efforts to offer the best products to our customers.
                            </div>
                            <img src={img1} className="w-75 mt-3" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}