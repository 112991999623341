import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

import CustomSlider from "../components/CustomSlider";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import DistMap from "../components/DistMap";

export default class AboutUs extends Component {
  render() {
    return (
      <div>
        <div>
          <CustomSlider />
        </div>
        <div className="container fullVh d-flex justify-content-center">
          <Row className="mb-4">
            <Col xs={12} className="text-center align-items-center mb-2">
              <h3 style={{ marginTop: "1.5rem" }}>Mission</h3>
            </Col>
            <Col xs={12}>
              <div className="w-75 mx-auto text-left pb-5 pt-3">
                At ENI Distributors/Distribution, we recognize the important
                role our company plays in the successful business operations of
                our customers. We are dedicated to maintaining strong
                partnerships with our clients. We will build these relationships
                by delivering high quality products, industry expertise, and
                access to exclusive brands. We will constantly explore the
                latest food products and trends in order to provide continuous
                value to our customers, both now and in the future.
              </div>
            </Col>
            <Col xs={12} className="text-center align-items-center mb-2">
              <h3 style={{ marginTop: "1.5rem" }}>General Information</h3>
            </Col>
            <Col xs={12}>
              <table className="mx-auto home-text">
                <tbody>
                  <tr>
                    <td className="text-right">Coporate Name</td>
                    <td></td>
                    <td></td>
                    <td className="bg-dark"></td>
                    <td></td>
                    <td></td>
                    <td className="text-left">ENI DIST, INC</td>
                  </tr>
                  <tr>
                    <td className="text-right">President</td>
                    <td></td>
                    <td></td>
                    <td className="bg-dark"></td>
                    <td></td>
                    <td></td>
                    <td className="text-left">Seung Hoon Lee</td>
                  </tr>
                  <tr>
                    <td className="text-right">Business Line</td>
                    <td></td>
                    <td></td>
                    <td className="bg-dark"></td>
                    <td></td>
                    <td></td>
                    <td className="text-left">
                      ASIAN FOOD IMPORTER AND DISTRIBUTOR
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">Year of Establishment</td>
                    <td></td>
                    <td></td>
                    <td className="bg-dark"></td>
                    <td></td>
                    <td></td>
                    <td className="text-left">2000</td>
                  </tr>
                  <tr>
                    <td className="text-right">Address</td>
                    <td></td>
                    <td></td>
                    <td className="bg-dark"></td>
                    <td></td>
                    <td></td>
                    <td className="text-left">
                      7020 Columbia Gateway Dr. Columbia, MD 21046
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">Tel</td>
                    <td></td>
                    <td></td>
                    <td className="bg-dark"></td>
                    <td></td>
                    <td></td>
                    <td className="text-left">301-477-4010</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col xs={12} className="text-center align-items-center mb-2">
              <h3 style={{ marginTop: "1.5rem" }}>Sales Territory</h3>
              <div className="w-75 mx-auto text-left pb-5 pt-3">
                We provide our products throughout the United States. We
                distribute to NY, NJ, MD, VA, GA TX, FL, and IL through our
                local sales team. Our container sales team delivers the best
                Korean brands to your business directly through Oakland,
                Seattle, New York, Chicago, Baltimore, Savannah, and Houston
                ports. You are one step behind until you meet the best Korean
                brands. Take one more step with confidence.
              </div>
            </Col>
            <Col xs={12} className="text-center">
              <DistMap />
            </Col>

            <Col xs={12} className="text-center align-items-center mb-2">
              <h3 style={{ marginTop: "1.5rem" }}>History</h3>
            </Col>
            <Col xs={12}>
              <VerticalTimeline>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentArrowStyle={{
                    borderRight: "7px solid  rgb(33, 150, 243)",
                  }}
                  date="August 2000"
                  iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
                >
                  <p className="mt-auto">Seohae Fishery, Inc Founded</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="May 2006"
                  iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
                >
                  <p className="mt-auto">
                    Starting sales Product of Dongwon F&B
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="January 2007"
                  iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
                >
                  <p className="mt-auto">
                    Starting sales Product of Samyang Food
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="September 2007"
                  iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
                >
                  <p className="mt-auto">
                    Changed name of company as SH USA, INC
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--education"
                  date="August 2008"
                  iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
                >
                  <p>Starting sales Product of Crown Confectionery</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--education"
                  date="August 2011"
                  iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
                >
                  <p className="mt-auto">ENI DIST, INC Founded</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--education"
                  date="September 2014"
                  iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
                >
                  <p>Relocated in Columbia, Maryland</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
                  date="April 2019"
                >
                  <p className="mt-auto">Relocated in Beltsville, Maryland</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  iconStyle={{
                    background: "rgb(60, 0, 100)",
                    color: "#fff",
                  }}
                  date="October 2023"
                >
                  <p className="mt-auto">
                    Headquarter relocated to Columbia, Maryland
                  </p>
                </VerticalTimelineElement>
              </VerticalTimeline>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
