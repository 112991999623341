import React, { Component } from 'react';

import Slider from 'react-animated-slider';

import "react-animated-slider/build/horizontal.css";
import "normalize.css/normalize.css";
import "../assets/css/slider-animation.css";
import "../assets/css/style.css";

import img1 from '../assets/picture/mod_warehouse.jpg';
import img2 from '../assets/picture/frontdoor.jpg';

const content = [
    {
      title: "Overcoming Challenges Through Passion",
      image: img1,
    },
    {
      title: "Promoting Healthy Food Culture",
      image: img2,
    },
    {
        title: "Overcoming Challenges Through Passion",
        image: img1,
      },
      {
        title: "Promoting Healthy Food Culture",
        image: img2,
      }
  ];

export default class CustomSlider extends Component {
    render() {
        return (
            <Slider className="slider-wrapper" infinite={true} autoplay={2000}>
                {content.map((item, index) => (
                    <div
                    key={index}
                    className="slider-content"
                    style={{ background: `url('${item.image}') no-repeat center center` }}
                    >
                    <div className="inner">
                        <h1>{item.title}</h1>                        
                    </div>
                    </div>
                ))}
            </Slider>
        );
    }
}