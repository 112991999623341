import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { loadReCaptcha } from 'react-recaptcha-google';
import { Helmet } from 'react-helmet';

//Components
import Navbar from './components/Navbar';
import Footer from './components/Footer';

//Pages
import Home from './pages/Home';
import Products from './pages/Products';
import Brands from './pages/Brands';
import Contact from './pages/Contact';
import AboutUs from './pages/AboutUs';


//CSS
import './App.css';

export default class App extends Component {
  
  componentDidMount(){
    loadReCaptcha();
  }
  
  render(){
    return (
      <div className="App">
          <Helmet>
            <title>ENI Dist.</title>
            <meta name="description" content="ENI DIST, Inc. imports and distributes authentic Asian food from South Korea and Southeast Asia. Our goal is to provide customers with quality Asian food through efficient and trustworthy service and ensure the satisfaction of customer experience. ENI DIST, Inc. currently services wholesale distributors, chain retailers, foodservice distributors, and independent supermarkets. Our warehouse manages dry, refrigerated, and frozen products as well as container drop shipments." />
          </Helmet>
        
          <Navbar />
          <div className="content-background mb-0">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/aboutus" component={AboutUs} />
              <Route exact path="/products" component={Brands} />
              {/* <Route exact path="/products" component={Products} /> */}
              <Route exact path="/contact" component={Contact} />
            </Switch>
          </div>
          <Footer />
        
      </div>
    );
  }
}
